import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule, TooltipModule } from '@core';
import { CategoryCeModel, categoryCeVIDE } from '@core/models/ce/category-ce-model';
import { ChantDesperanceTextField } from '@core/models/ce/chant-desperance-model';
import { GroupeCeModel, groupeCeVIDE } from '@core/models/ce/groupe-ce-model';
import { GenreMusicalModel, genreOuStyleMusicalVIDE, StyleMusicalModel } from '@core/models/ce/chant-genre-style-model';
import { CategoryCeService } from '@core/service/ce/categoryCE/category-ce.service';
import { GroupeCeService } from '@core/service/ce/groupCE/groupe-ce.service';
import { CommonService } from '@core/service/common/common.service';
import { GroupeCuService } from '@core/service/cu/groupe-cu/groupe-cu.service';
import { GenreMusicalService } from '@core/service/genre-musical/genre-musical.service';
import { StyleMusicalService } from '@core/service/style-musical/style-musical.service';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { keyValueYesNoList, keyValueTypeChantList } from '@shared/tools/enums/enum-status.enum';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { albumGroupeVIDE } from '@core/models/ce/album_groupe-model';

@Component({
  selector: 'app-search-advance-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MessagesModule, ConfirmDialogModule, ToastModule,
    IconFieldModule, InputTextModule, InputIconModule, MultiSelectModule, DropdownModule,
    CommonModule,
    ListboxModule,
    TooltipModule,
  ],
  templateUrl: './search-advance-dialog.component.html',
  styleUrl: './search-advance-dialog.component.scss'
})
export class SearchAdvanceDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {

public textField=ChantDesperanceTextField;
  public isCU: boolean= false;
  public isCUPublic: boolean= false;
  public keyValueYesNoList = keyValueYesNoList;
  public typeChantList = keyValueTypeChantList

  public categoryDataList:any = [];
  public groupeDataList:any = [];

  //public responseGroupeCEObservable: any;
  //public responseCategoryCEObservable: any;

  constructor( public fb: FormBuilder,
    public commonService: CommonService,
    public dynamicDialogConfig: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
    public categoryCeService: CategoryCeService,
    public groupeCeService: GroupeCeService,
    public groupeCuService: GroupeCuService,
    public genreMusicalService: GenreMusicalService,
    public styleMusicalService: StyleMusicalService,
  ){
    super();
    //if( dynamicDialogConfig && dynamicDialogConfig.data ){
      this.isCU = dynamicDialogConfig.data.isCU!=undefined?dynamicDialogConfig.data.isCU:false;
      this.isCUPublic = dynamicDialogConfig.data.isCUPublic!=undefined?dynamicDialogConfig.data.isCUPublic:false;

      if( !this.isCUPublic ){
        this.chantFilter = dynamicDialogConfig.data.chantFilter;
        this.categoryDataList=dynamicDialogConfig.data.categoryDataList;
        this.groupeDataList=dynamicDialogConfig.data.groupeDataList;
      }else{
        this.filterPublic = dynamicDialogConfig.data.filterPublic;
      }
    //}
  }

  public get genreMusicalDataList(): GenreMusicalModel[] { return this.genreMusicalService.genreMusicalDataList; };
  public get genreMusicalDataListSelected(): GenreMusicalModel[] { return this.genreMusicalService.genreMusicalDataListSelected; };
  public get styleMusicalDataList():StyleMusicalModel[] { return this.genreMusicalService.styleMusicalDataList; };
  public get styleMusicalDataListSelected(): StyleMusicalModel[] { return this.genreMusicalService.styleMusicalDataListSelected; };

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    if( !this.isCUPublic ){
      if( this.isCU ){
        this.loadDataCategoryCU();
        this.loadDataGroupeCU();
      }else{
        this.loadDataCategoryCE();
        this.loadDataGroupeCE();
      }
    }

    if( this.isCUPublic ){
      this.loadDataGenreMusical();
      //this.loadDataStyleMusicalCU();
    }
  }
  async loadDataCategoryCE()  {
    if(this.categoryDataList?.length<=0){
      this.subs.sink = await this.categoryCeService.getAll().subscribe({
        next: (result) => {
          this.categoryDataList = result;
          this.categoryDataList.unshift(categoryCeVIDE);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }
  async loadDataGroupeCE()  {
    if(this.groupeDataList?.length<=0){
      this.subs.sink = await this.groupeCeService.getAll().subscribe({
        next: (result) => {
          //this.groupeDataList.push(groupeCeVIDE)
          this.groupeDataList = result;
          this.groupeDataList.unshift(groupeCeVIDE);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }

  async loadDataCategoryCU()  {
    if(this.categoryDataList?.length<=0){
      this.subs.sink = await this.categoryCeService.getAll().subscribe({
        next: (result) => {
          this.categoryDataList = result;
          this.categoryDataList.unshift(categoryCeVIDE);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }
  async loadDataGroupeCU()  {
    if(this.groupeDataList?.length<=0){
      let userName = this.commonService.authorizationService.userConnected.userName;
      this.subs.sink = await this.groupeCuService.getAll(userName).subscribe({
        next: (result) => {
          this.groupeDataList = result;
          this.groupeDataList.unshift(albumGroupeVIDE);
          console.log(`this.groupeDataList:`, this.groupeDataList);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }
  async loadDataGenreMusical()  {
    if(this.genreMusicalService.genreMusicalDataList?.length<=0){
      this.subs.sink = await this.genreMusicalService.getAll().subscribe({
        next: (result) => {
          this.genreMusicalService.genreMusicalDataList = result;
          //this.genreMusicalDataList.unshift(genreOuStyleMusicalVIDE);
          console.log(`this.genreMusicalDataList:`, this.genreMusicalService.genreMusicalDataList);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }
  onChangeGenre(event:any) {
    let gmData = event.value;
    console.log(`ON.CHANGE GENRE:`,gmData);
    this.genreMusicalService.styleMusicalDataList=[];
    this.filterPublic.genreMusicalNameDisplay=[];
    this.filterPublic.genreMusicalIds=[];
    this.genreMusicalService.styleMusicalDataListSelected=[];
    this.filterPublic.styleMusicalIds = undefined;
    this.filterPublic.styleMusicalNameDisplay=undefined;

    gmData.forEach((gmItem: any) => {
      this.filterPublic.genreMusicalIds?.push(gmItem.id);
      this.filterPublic.genreMusicalNameDisplay?.push(gmItem.name);
      if( gmItem.styleMusicals.length > 0 ){
        gmItem.styleMusicals.forEach((dt: any) => {
          // let val:boolean = this.genreMusicalService.styleMusicalDataListSelected.includes(permissions);
          this.genreMusicalService.styleMusicalDataList.push(dt);
        });
      }
    });
    console.log(`filterPublic:`,this.filterPublic);
  }
  onChangeStyle(event:any) {
    let data = event.value;
    this.filterPublic.styleMusicalNameDisplay=[];
    this.filterPublic.styleMusicalIds=[];
    console.log(`ON.CHANGE STYLE:`,data);
    data.forEach((item: any) => {
      this.filterPublic.styleMusicalIds?.push(item.id);
      this.filterPublic.styleMusicalNameDisplay?.push(item.name);
    });
    console.log(`filterPublic:`,this.filterPublic);
  }
  onSelectAllChange(event:any) {
    let data = event.value;
    console.log(`ON.SELECTED.ALL.CHANGE STYLE:`,event);
    console.log(`ON.SELECTED.ALL.CHANGE STYLE:`,data);
  }


onClose(){
  let list = {};
  list = {
    groupeDataList: this.groupeDataList,
    categoryDataList: this.categoryDataList,
  };
  if( this.isCUPublic ){
    list = {
      genreMusicalDataList: this.genreMusicalService.genreMusicalDataList,
      styleMusicalDataList: this.genreMusicalService.styleMusicalDataList,
    };
  }
  this.dynamicDialogRef.close({ list: list });
}

onSubmit(): void {
  this.clear();
  let data =this.chantFilter;
  let list = {};
  list = {
    groupeDataList: this.groupeDataList,
    categoryDataList: this.categoryDataList,
  };
  if( this.isCUPublic ){
    list = {
      genreMusicalDataList: this.genreMusicalService.genreMusicalDataList,
      styleMusicalDataList: this.genreMusicalService.styleMusicalDataList,
    };
   data =this.filterPublic;
  }
  this.dynamicDialogRef.close({isReloadPage: true, list: list, data: data});
}

clear() {
  this.commonService.messageService.clear();
}

onError(error: any){
  console.log(error.message);
}

}
