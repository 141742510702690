export enum EnumStatus {
  Disable=0,
  Enable=1
}
export enum EnumStatusPublish
{
  Public = 1,
  Friend = 2,
  Private = 3
}
export enum EnumGroupeChantant
{
  CHANT_INTERNAUTE = 23
}
export enum EnumFields {
  SEARCH_STR=1,
  GROUPE_ID=2,
  STYLE_MUSICAL_ID=3,
  GENRE_MUSICAL_ID=4,
  ARTIST_ID=5,
  ARTIST_NAME=6,
}
export enum EnumAction {
  AUDIO=0,
  DIAGRAMME_NOTE=1,

  CALL_LIKE_FUNC=2,
  CALL_FAVORIS_FUNC=3,
  CALL_HASKNOW_FUNC=4,
}

export enum EnumPosition {
  LEFT='tl',
  CENTER='tc',
  RIGHT='tr',
}
export enum EnumLangue {
  FR='FR',
  HT='HT',
  EN='EN',
  ES='ES',
}
export const enumStatus = [
  { label:'Enable', value: EnumStatus.Enable},
  { label:'Disable', value: EnumStatus.Disable},
];
export const EnumStatusList = [
  { label:'Enable', value: EnumStatus.Enable.toString()},
  { label:'Disable', value: EnumStatus.Disable.toString()},
];
export const enumStatus1: Record<EnumStatus, string> = {
  [EnumStatus.Enable]:  `${EnumStatus.Enable}`,
  [EnumStatus.Disable]: `${EnumStatus.Disable}`,
};
export const keyValueYesNoList = [
  { label:'All', value: ''},
  { label:'Yes', value: EnumStatus.Enable},
  { label:'No', value: EnumStatus.Disable},
];
export const keyValueTypeChantList = [
  { label:'All', value: ''},
  { label:'FR', value: EnumLangue.FR},
  { label:'HT', value: EnumLangue.HT},
  { label:'EN', value: EnumLangue.EN},
  { label:'ES', value: EnumLangue.ES},
];
