import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { InfoService } from '@core';
import { ChantUserTextField } from '@core/models/cu/chant-user-model';
import { FilterPublicModel } from '@core/models/filter-search';
import { CommonService } from '@core/service/common/common.service';
import { ChantUserService } from '@core/service/cu/chantuser/chantuser.service';
import { GenreMusicalService } from '@core/service/genre-musical/genre-musical.service';
import { GlobalService } from '@core/service/global.service';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { SearchAdvanceDialogComponent } from '@shared/components/search-advance-dialog/search-advance-dialog.component';
import { ImportALlModule } from '@shared/imports';
import { ID, S } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { EnumFields } from '@shared/tools/enums/enum-status.enum';
import { EnumText } from '@shared/tools/enums/enum-Text';

@Component({
  selector: 'app-panel-search-bar-laterale',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './panel-search-bar-laterale.component.html',
  styleUrl: './panel-search-bar-laterale.component.scss'
})
export class PanelSearchBarLateraleComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  public textField=ChantUserTextField;
  public enumFields=EnumFields;
  @Output() onReloadSearchEventEmitter = new EventEmitter<any>();

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public globalService: GlobalService,
    public commonService: CommonService,
    public chantUserService: ChantUserService,
    public infoService: InfoService,
    public elementRef: ElementRef,
    public matDialog: MatDialog,
    public mediaPlayerService: MediaPlayerService,
    public genreMusicalService: GenreMusicalService,
  ){
    super();
    this.filterPublic=new FilterPublicModel();
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params[ID.toLowerCase()]){
        this.filterPublic.searchStr = params[ID.toLowerCase()];
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if ( params[S.toLowerCase()] ) {
        this.filterPublic.searchStr = params[S];
      }
    });
    //this.loadData();
  }

  public loadData(){
    this.onReloadSearchEventEmitter.emit();
  }

  showFormSearchAdvanced() {
    let data = {
      isCU: true,
      isCUPublic: true,
      filterPublic: this.filterPublic,
      //groupeDataList: this.groupeDataList,
      //categoryDataList: this.categoryDataList,
    };
    const ref = this.commonService.dialogService.open(SearchAdvanceDialogComponent, {
        header: EnumText.SearchCritere,
        contentStyle: {"overflow":"auto"},
        //baseZIndex: 10000,
        width: '60%',
        data: data
    });

    ref.onClose.subscribe(( result ) => {
        if (result) {
          if( result.isReloadPage ){
            this.filterPublic.searchStr=result.data.searchStr;
            // this.filterPublic.styleMusicalId=result.data.styleMusicalId;
            // this.filterPublic.artistId=result.data.artistId;

            this.filterPublic.groupeId=result.data.groupeId;
            // this.filterPublic.genreMusicalId=result.data.genreMusicalId;
            console.log('genre.MusicalDataListSelected',this.genreMusicalService.genreMusicalDataListSelected);
            console.log('style.MusicalDataListSelected',this.genreMusicalService.styleMusicalDataListSelected);

            this.loadfirst=true;
            this.loadData();
          }
          //if(result.list.categoryDataList){ this.categoryDataList=result.list.categoryDataList; }
          //if(result.list.groupeDataList){ this.groupeDataList=result.list.groupeDataList; }
        }
    });
  }
  clear(field: number, data: string) {
    switch (field) {
      case this.enumFields.SEARCH_STR:
        this.filterPublic.searchStr='';
        break;
      case this.enumFields.GROUPE_ID:
        this.filterPublic.groupeId ='';
        break;
      case this.enumFields.STYLE_MUSICAL_ID:
        this.filterPublic.styleMusicalIds =[];
        this.filterPublic.styleMusicalNameDisplay =[];
        break;
      case this.enumFields.GENRE_MUSICAL_ID:
        this.filterPublic.genreMusicalIds =[];
        this.filterPublic.genreMusicalNameDisplay =[];
        break;
      case this.enumFields.ARTIST_ID:
        this.filterPublic.artistIds =[];
        break;
      case this.enumFields.ARTIST_NAME:
        this.filterPublic.artistNameDisplay =[];
        break;
      default:
        this.searchValue =''
        this.filterPublic.searchStr='';
        this.filterPublic.groupeId ='';
        this.filterPublic.styleMusicalIds =[];
        this.filterPublic.styleMusicalNameDisplay =[];
        this.filterPublic.genreMusicalIds =[];
        this.filterPublic.genreMusicalNameDisplay =[];
        this.filterPublic.artistIds =[];
        this.filterPublic.artistNameDisplay =[];
        break;
    }
    this.loadData();
    const queryParams = { s: this.filterPublic.searchStr,};
    this.router.navigate([MenuLink.SearchPagePublic ], { queryParams });
  }
  public get initSearch(): boolean{
    return (this.filterPublic.searchStr!=='' && this.filterPublic.searchStr!= undefined)
    || (this.filterPublic.groupeId!=='' && this.filterPublic.groupeId!= undefined)
    || (!this.filterPublic.styleMusicalIds && this.filterPublic.styleMusicalIds!= undefined)
    || (!this.filterPublic.genreMusicalIds && this.filterPublic.genreMusicalIds!= undefined)
    || (!this.filterPublic.artistIds && this.filterPublic.artistIds!= undefined);
  }
  public search(){

    this.loadfirst=true;
    this.loadData();

    const queryParams = { s: this.filterPublic.searchStr,};
    this.router.navigate([MenuLink.SearchPagePublic ], { queryParams });
  }

}
