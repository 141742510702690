<div class="hearderPadding"></div>
<div class="container">

  <p-breadcrumb class="myBreadcrumbCE" [model]="menuItemBreadcrumb">
    <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item.route; else elseBlock">
            <a [routerLink]="item.route" class="p-menuitem-link" 
              pTooltip="Click to go back" tooltipPosition="bottom">
                <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
                &nbsp;<span class="text-primary font-semibold">{{ item.label }}</span>
            </a>
        </ng-container>
        <ng-template #elseBlock>
            <a [href]="item.url">
              <span class="text-color">{{ item.label }}</span>
            </a>
        </ng-template>
    </ng-template>
  </p-breadcrumb>

  <div class="card1">
    <p-tabView class="tabViewCE" (onChange)="onChangeTabView($event)" [activeIndex]="selectedIndex">
    <p-tabPanel *ngFor="let row of tabRowsDataDto; let i = index" [selected]="i == 0"
      leftIcon="pi pi-folder-open" [header]="row.langue">
      <ng-template pTemplate="header">
        <i class="fa fa-folder-open"></i> {{row.langue}} <span class="badge bg-grey">{{row.count}}</span>
      </ng-template>
      <app-p-table-ce-items [chantDesperanceList]="row.chantDesperanceList"></app-p-table-ce-items>
    </p-tabPanel>
    </p-tabView>
    </div>

</div>
