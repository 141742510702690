import { OFFSET } from '@shared/tools/constants/constants';
import { CategoryCeModel } from './category-ce-model';
import { GroupeCeModel } from './groupe-ce-model';
import { AlbumGroupeModel } from './album_groupe-model';
import { EnumGroupeChantant, EnumStatusPublish } from '@shared/tools/enums/enum-status.enum';
import { ChantArtistModel, ChantGenreMusicalModel, ChantStyleMusicalModel, GenreMusicalModel, StyleMusicalDto } from './chant-genre-style-model';
import { ArtistModel } from '../cu/artist-model';
import { UserModel } from '../user/user-model';
import { ChantShareModel } from './chant-share-model';

export class ChantDesperanceModel {
  userId: string='';
  user: UserModel = new UserModel();
  userIdPath: string='';
  userIdAudioPath: string='';
  userIdDiagrammeNotePath: string='';

  position: string='';
  titre: string='';
  contenu: string='';
  contenuPur: string='';
  contenuTFMob: string='';
  auteurChant: string='';
  typeChant: string="FR";
  groupeChantantId:number =0;
  categorieChantId:number =0;
  isChantChoeur: boolean = false;

  isApprouve: boolean = false;

  audioPathBase64: string='';
  audioPath: string='';
  contentTypeAudio: string='';
  sizeAudio: string='';
  artistAudio: string='';
  albumAudio: string='';
  genreAudio: string='';
  dureeAudio: string='';
  extentionAudio: string='';

  isHaveOtherAudio: boolean = false;
  audioInstrumentPath: string='';
  contentTypeAudioInstrument: string='';
  sizeAudioInstrument: string='';
  dureeAudioInstrument: string='';
  diagrammeNotePath: string='';
  diagrammeNotePathBase64: string='';

  contentTypeDiagrammeNote: string='';
  sizeDiagrammeNote: string='';
  extentionDiagrammeNote: string='';

  albumGroupeId: string='';

  id:number =0;
  // ... //
  versionCode:number =0;
  nbrOfViews:number =0;
  nbrOfLikes:number =0;
  nbrOfFavoris:number =0;
  isUpdate: boolean= false;
  // ... //
  createdBy: string='';
  dateCreated: string='';
  dateModif: string='';

  contenuUnicode: string='';
  contenuHtml: string='';

  videoLink: string='';

  isUpdateLocale: boolean = false;
  isAudioLocal: boolean = false;
  isAudioInstrumentLocal: boolean = false;
  isDiagrammeNoteLocal: boolean = false;
  isSyncToServerOnline: boolean = true;

  categorieChant: CategoryCeModel = new CategoryCeModel();
  groupeChantant: GroupeCeModel= new GroupeCeModel();
  albumGroupe: AlbumGroupeModel | any = new AlbumGroupeModel();
  chantShare: ChantShareModel | any = new ChantShareModel();

  // chantFavoris: ChantFavorisModel[]=[];
  // chantDesperanceLikes: chantDesperanceLikesModel= [];
  // chantDesperanceAudios: ChantDesperanceAudioModel= [];
  // chantDesperanceDiagrammeNotes: ChantDesperanceDiagrammeNoteModel[]= [];

  nbrLike:number =0;
  nbrDisLike:number =0;
  nbrAudio:number =0;
  isHasAudio: boolean=false;
  nbrDiagrammeNote =0;
  isHasDiagrammeNote: boolean=false;
  dateCreatedStr: string='';
  declareateModifStr: string='';
  audioPathUrl: string='';
  audioInstrumentPathUrl: string='';

  allAudioPathUrl: string[]=[];
  diagrammeNotePathUrl: string='';
  allDiagrammeNotePathUrl: string[]=[];

  audioSelected:any
  isDiagrammeNoteSelected=false;

  isSaveOnline:boolean = true;
  hasChantUserGroupe:boolean=false;

  statutShared: string='';
  isCanUpdate:boolean=false;
  isCanShare:boolean=false;
  dateModifStr: string='';
  nbrShare: number = 0;


  //statusPublish: string='';// = (int?)EnumStatusPublish.Public;
  statusPublish:number = EnumStatusPublish.Public;
  statusPublishAdminYN:boolean = true;

  chantGenreMusicals: ChantGenreMusicalModel[] = [];
  chantStyleMusicals:ChantStyleMusicalModel[] = [];
  chantArtists:ChantArtistModel[] = [];

  genreMusicalList: string[] = [];
  styleMusicalList:string[] = [];
  artistList:ArtistModel[] = [];

  // UI
  artistListSelected: ArtistModel[] = [];
  genreMusicalListSelected: GenreMusicalModel[] = [];
  styleMusicalDataDtoListSelected:StyleMusicalDto[] = [];

  get nomGroupeOuAlbum(): string {
    if (this.id > 0) {
      if (this.groupeChantantId === EnumGroupeChantant.CHANT_INTERNAUTE) {
        if (this.albumGroupe != null) {
          return this.albumGroupe.nom;
        }
      } else {
        if (this.groupeChantant != null) {
          return this.groupeChantant.nom;
        }
      }
    }
    return '';
  }
}

export enum ChantDesperanceTextField {
  title='Chant d\'Esperance',
  position='#track',
  titre ='title' ,
  contenu ='contenu' ,
  auteurChant ='auteur' ,
  typeChant ="type" ,
  groupeChantantId ='groupe' ,
  categorieChantId ='Categorie' ,
  isChantChoeur = 'isChoeur' ,
  audioPath ='audioPath' ,
  contentTypeAudio ='contentTypeAudio' ,
  sizeAudio ='sizeAudio' ,
  artistAudio ='artist Audio' ,
  albumAudio ='album Audio' ,
  genreAudio ='genre Audio' ,
  dureeAudio ='duree Audio' ,
  isHaveOtherAudio = 'isHaveOtherAudio' ,
  audioInstrumentPath ='audioInstrumentPath' ,
  contentTypeAudioInstrument ='contentTypeAudioInstrument' ,
  sizeAudioInstrument ='sizeAudioInstrument' ,
  dureeAudioInstrument ='dureeAudioInstrument' ,
  diagrammeNotePath ='File / Link Music Theory' ,
  contentTypeDiagrammeNote ='contentTypeDiagrammeNote' ,
  sizeDiagrammeNote ='sizeDiagrammeNote' ,

  id ='id' ,
  // ... //
  versionCode ='ver' ,
  nbrOfViews ='nbrOfViews' ,
  nbrOfLikes ='nbrOfLikes' ,
  nbrOfFavoris ='nbrOfFavoris' ,
  isUpdate= 'isUpdate' ,
  // ... //
  createdBy ='createdBy' ,
  dateCreated ='dateCreated' ,
  dateModif ='dateModif' ,

  contenuUnicode ='contenuUnicode' ,
  contenuHtml ='contenuHtml' ,

  isUpdateLocale = 'isUpdateLocale' ,
  isAudioLocal = 'isAudioLocal' ,
  isAudioInstrumentLocal = 'isAudioInstrumentLocal' ,
  isDiagrammeNoteLocal = 'isDiagrammeNoteLocal' ,
  isSyncToServerOnline = 'isSyncToServerOnline' ,

  categorieChant= 'category' ,
  groupeChantant= 'groupe' ,

  // chantFavoris: ChantFavorisModel[]=[] ,
  // chantDesperanceLikes: chantDesperanceLikesModel= [] ,
  // chantDesperanceAudios: ChantDesperanceAudioModel= [] ,
  // chantDesperanceDiagrammeNotes: ChantDesperanceDiagrammeNoteModel[]= [] ,

  nbrLike ='nbrLike' ,
  nbrDisLike ='nbrDisLike' ,
  nbrAudio ='nbrAudio' ,
  isHasAudio='isHasAudio' ,
  nbrDiagrammeNote ='nbrDiagrammeNote' ,
  isHasDiagrammeNote='isHasDiagrammeNote' ,
  dateCreatedStr ='dateCreated' ,
  declareateModifStr ='declareateModif' ,
  audioPathUrl ='audioPathUrl' ,
  audioInstrumentPathUrl ='audioInstrumentPathUrl' ,

  allAudioPathUrl='allAudioPathUrl',
  diagrammeNotePathUrl ='diagrammeNotePathUrl' ,
  allDiagrammeNotePathUrl ='allDiagrammeNotePathUrl',
  descriptions ='descriptions' ,

}
