import { Injectable } from '@angular/core';
import { CategoryCeTextField } from '@core/models/ce/category-ce-model';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { ApiService } from '../../api/api.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryCeService {

  endpoint: string = ApiUrl.CategoryCE;
  public textField=CategoryCeTextField;
  public enumText= EnumText;

  constructor( private apiService: ApiService) {}

  getAll() : Observable<any[]>{
    return this.apiService.getData(`${this.endpoint}` );
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }

  delete( id: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }
}
