import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@core';
import { ChantDesperanceModel } from '@core/models/ce/chant-desperance-model';
import { ChantFavorisService } from '@core/service/ce/chantFavoris/chant-favoris.service';
import { ChantdesperanceHasKnowService } from '@core/service/ce/chantdesperance-hasknow/chantdesperance-hasknow.service';
import { ChantdesperanceLikeService } from '@core/service/ce/chantdesperance-like/chantdesperance-like.service';
import { ChantdesperanceService } from '@core/service/ce/chantdesperance/chantdesperance.service';
import { CommonService } from '@core/service/common/common.service';
import { GlobalService } from '@core/service/global.service';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { ID, IDG, ROW_INDEX, TC } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { EnumAction, EnumPosition } from '@shared/tools/enums/enum-status.enum';
import { LoginDialogFormComponent } from 'app/authentication/login-dialog-form/login-dialog-form.component';
import { delay } from 'bluebird';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-chant-view-details',
  standalone: true,
  imports: [
    ImportALlModule,
    DynamicDialogModule
  ],
  providers: [DialogService],
  templateUrl: './chant-view-details.component.html',
  styleUrl: './chant-view-details.component.scss'
})
export class ChantViewDetailsComponent extends UnsubscribeOnDestroyAdapter implements OnInit
{
  public countRowIndex:number = 0;
  public typeChant:string = "fr"
  public groupeId:number = 12
  public chantId:number = 206
  public isFavoris:boolean = false;
  public isLike:boolean = false;
  public isKnow:boolean = false;
  @Input() isViewOnly=false;
  @Input() chantDesperanceInput: ChantDesperanceModel=new ChantDesperanceModel();
  public isShowPrecedentRecordBtn:boolean = false;
  public isShowNextRecordBtn:boolean = false;
  public isShowZoom:boolean = false;
  public enumActionCall =EnumAction;
  public position= EnumPosition;

  constructor(
    public router: Router,
    public globalService: GlobalService,
    public commonService: CommonService,
    public dynamicDialogRef: DynamicDialogRef,
    public activatedRoute: ActivatedRoute,
    public chantFavorisService: ChantFavorisService,
    public chantdesperanceService: ChantdesperanceService,
    public chantdesperanceLikeService: ChantdesperanceLikeService,
    public chantdesperanceHasKnowService: ChantdesperanceHasKnowService,
    public mediaPlayerService: MediaPlayerService,
  ) {
      super();
      this.chantId = 206;
    //console.log('constructor:',this.chantDesperance);
  }

  ngOnInit(){
      if( this.isViewOnly ){
        this.chantId = this.chantDesperanceInput.id;
        this.chantdesperanceService.sessionChantdesperanceSelected = this.chantDesperanceInput;
        console.log('this.chantId:',this.chantId);
        console.log('this.chantDesperanceInput:',this.chantDesperanceInput);
      }
    this.activatedRoute.queryParams.subscribe(params => {
      const id = params[ID.toLowerCase()];

      if ( params[TC] ) {
        this.typeChant = params[TC];
      }
      if ( params[IDG] ) {
        this.groupeId = params[IDG];
      }
      if ( params[ROW_INDEX] ) {
        this.countRowIndex = params[ROW_INDEX];
      }
      //console.log(`typeChant: ${this.typeChant} | groupeId: ${this.groupeId} | countRowIndex: ${this.countRowIndex} | chantId: ${this.chantId}`);
    });
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params[ID.toLowerCase()]){
        this.chantId = params[ID.toLowerCase()];
      }
      this.loadDataQuery();
    });

    // this.title = this.chantdesperanceService.sessionChantdesperanceSelected.titre;
    // this.menuItemBreadcrumb.push({ icon: 'pi pi-arrow-circle-left', label: `BACK`, route: `${MenuLink.GroupeCEPublic}` });
    // this.menuItemBreadcrumb.push({ icon: 'pi pi-book', label: `${this.title}`, route: `${MenuLink.GroupeCEPublic}` });

    // await this.loadChant();
    // console.log('PViewDetailsCeComponent : ngOnInit : AFTER::loadChant:');
    //// this.updateCEView();
  }
  async loadDataQuery(){
    this.title = this.chantdesperanceService.sessionChantdesperanceSelected.titre;
    this.menuItemBreadcrumb.push({ icon: 'pi pi-arrow-circle-left', label: `BACK`, route: `${MenuLink.GroupeCEPublic}` });
    this.menuItemBreadcrumb.push({ icon: 'pi pi-book', label: `${this.title}`, route: `${MenuLink.GroupeCEPublic}` });

    await this.loadChant();
    //this.updateCEView();
  }

  get dataCE():ChantDesperanceModel{
    return this.chantdesperanceService.sessionChantdesperanceSelected;
  }

  updateCEView(){
    if( !this.isViewOnly ){
      this.chantdesperanceService.updateCEView(this.chantId).subscribe({
        next:(result) => { console.log(`updateCEView::next`, result); },
        //complete: () => { console.log(`updateCEView::complete`); },
        error: (ex) => { console.log(`updateCEView::error`, ex); }
      });
    }
  }

  public async loadChant(){
    console.log('loadChant: DEBUT', this.chantId);
    if (this.chantId > 0) {
      await this.getChantDesperanceById();
    }else{
      this.countRowIndex = 0
      this.typeChant = "fr"
      this.groupeId = 12
      this.chantId = 206
      await this.getChantDesperanceById();
    }
    await this.getDataChantByGroupeByType()

    let ceSelected = this.chantdesperanceService.sessionChantdesperanceSelected;

    if (this.chantdesperanceService.sessionChantdesperanceCurrentList.length > 0) {
      let newIndexChant:number = this.chantdesperanceService.sessionChantdesperanceCurrentList.findIndex((x)=> x.id == ceSelected.id);
      //console.log(`loadChant: newIndexChant: ${newIndexChant} != countRowIndex: ${this.countRowIndex}`);

      if (newIndexChant != this.countRowIndex) {
        //console.log(`loadChant: countRowIndex: ${this.countRowIndex}`);
        this.countRowIndex = newIndexChant>-1?newIndexChant:0;
        //console.log(`loadChant: countRowIndex::EDIT:: ${this.countRowIndex}`);
        //txt_countRowIndex.Text = newIndexChant
        const queryParams = {
          tc: ceSelected.typeChant,
          ri: this.countRowIndex,
          IDG: ceSelected.groupeChantantId,
        };
        if( !this.isViewOnly ){
          this.router.navigate([MenuLink.ChantDesperanceDetailsPublic, ceSelected.id ], { queryParams });
        }
      }
    }

    let chantListCount: number = this.chantdesperanceService.sessionChantdesperanceCurrentList.length;
    this.isShowPrecedentRecordBtn = chantListCount > 0 && this.countRowIndex > 0
    this.isShowNextRecordBtn = chantListCount > 0 && chantListCount - 1 > this.countRowIndex
    //console.log('loadChant: FIN');
  }
  async getChantDesperanceById(onlyLoad:boolean=false){
    //if( this.chantId > 0 && this.chantId != this.chantdesperanceService.sessionChantdesperanceSelected.id){
      //console.log('getChantDesperanceById: DEBUT');
      //this.globalService.showLoader();
      if( !this.callRunning2 ){
        this.callRunning2=true;
        this.subs.sink = await this.chantdesperanceService.getOne( this.chantId ).subscribe({
          next:(result)=>{
            this.chantdesperanceService.sessionChantdesperanceSelected = result;
            this.globalService.showLoader(false);
            // console.log('getChantDesperanceById: Selected :next:',this.chantdesperanceService.sessionChantdesperanceSelected);
            //console.log('getChantDesperanceById: CurrentList :next:',this.chantdesperanceService.sessionChantdesperanceCurrentList);
            if( !onlyLoad ){
              this.updateCEView();
              this.checkIsAllDATA();
            }
            this.callRunning2=false;
          },
          complete:()=>{
            this.globalService.showLoader(false);
            this.callRunning2=false;
            //console.log('getChantDesperanceById: complete');
          },
          error: (ex)=>{
            console.log('getChantDesperanceById: error', ex);
            this.globalService.showLoader(false);
            this.callRunning2=false;
          }
        });
      }
      while(this.callRunning2){
        await delay(100);
        //console.log('getChantDesperanceById: delay(100)');
      }
      //console.log('getChantDesperanceById: FIN');
    // }else{
    //   if( !onlyLoad ){
    //     this.updateCEView();
    //     this.checkIsAllDATA();
    //   }
    // }
  }

  public async getDataChantByGroupeByType(){
    if( !this.isViewOnly ){
      let ceSelected = this.chantdesperanceService.sessionChantdesperanceSelected;
      //console.log('getDataChantByGroupeByType: DEBUT');
      if ( this.chantdesperanceService.sessionGroupeId != ceSelected.groupeChantantId) {
        //console.log('getDataChantByGroupeByType: sessionGroupeId != this.groupeId');
        this.chantdesperanceService.removeSessionChantdesperanceCurrentList();
      }
      if ( this.chantdesperanceService.sessionTypeChant != ceSelected.typeChant) {
        //console.log('getDataChantByGroupeByType: sessionTypeChant != this.typeChant');
        this.chantdesperanceService.removeSessionChantdesperanceCurrentList();
      }
      //console.log('getDataChantByGroupeByType: this.chantdesperanceService.sessionChantdesperanceCurrentList.length:',this.chantdesperanceService.sessionChantdesperanceCurrentList.length);

      if ( this.chantdesperanceService.sessionChantdesperanceCurrentList.length > 0){
        let data:ChantDesperanceModel[]=[];
        if ( this.chantdesperanceService.sessionChantdesperanceCurrentList.length > 0 ) {data = this.chantdesperanceService.sessionChantdesperanceCurrentList;}
        if ( this.chantdesperanceService.sessionChantdesperanceCurrentList.length > 0 ) {data = this.chantdesperanceService.sessionChantdesperanceCurrentList;}

        this.setDataChant(data);
      } else {
        this.globalService.showLoader();
        //console.log('getDataChantByGroupeByType: sessionChantdesperanceSelected::',this.chantdesperanceService.sessionChantdesperanceSelected);

        const queryParams = {  groupId: ceSelected.groupeChantantId, typeChant: ceSelected.typeChant,  isChoeur: 0,  };

        if( !this.callRunning1 ){
          this.callRunning1=true;
          this.subs.sink = await this.chantdesperanceService.getAllByGroupeByTypeV2( queryParams ).subscribe({
            next:(result)=>{
              this.setDataChant(result);
              this.globalService.showLoader(false);
              this.callRunning1=false;
            },
            complete: ()=>{this.globalService.showLoader(false); this.callRunning1=false;},
            error: (ex)=>{
              console.log(`error`, ex);
              this.globalService.showLoader(false);
              this.callRunning1=false;
            }
          });
        }
        while(this.callRunning1){
          await delay(100);
        }
      }
    }
    //console.log('getDataChantByGroupeByType: FIN');
  }

  public setDataChant(result: ChantDesperanceModel[]){
    //console.log('setDataChant: result :',result);
    this.chantdesperanceService.sessionChantdesperanceCurrentList = result
    this.chantdesperanceService.sessionTypeChant = this.typeChant;
    this.chantdesperanceService.sessionGroupeId = this.groupeId;

    let chantListCount: number = this.chantdesperanceService.sessionChantdesperanceCurrentList.length;
    this.isShowPrecedentRecordBtn = chantListCount > 0 && this.countRowIndex > 0
    this.isShowNextRecordBtn = chantListCount > 0 && chantListCount - 1 > this.countRowIndex
  }

  async goToRecordSong(ACTION_RECORD: string){
    await this.getDataChantByGroupeByType();
    if ( this.chantdesperanceService.sessionChantdesperanceCurrentList.length > 0 ) {
      if (ACTION_RECORD==this.enumActionRecord.RECORD_PRECEDENT) {
        this.countRowIndex--;
      }
      //console.log(`goToRecordSong(${ACTION_RECORD}): BEFORE [ +=1 ] this.countRowIndex:: `, this.countRowIndex);
      if (ACTION_RECORD==this.enumActionRecord.RECORD_NEXT) {
        this.countRowIndex++;
      }
      //console.log(`goToRecordSong(${ACTION_RECORD}): AAFTER [ +=1 ] this.countRowIndex:: `, this.countRowIndex);
      //txt_countRowIndex.Text = countRowIndex
      let item = this.chantdesperanceService.sessionChantdesperanceCurrentList[this.countRowIndex];

      this.chantdesperanceService.sessionChantdesperanceSelected = item
      this.setDataChant(this.chantdesperanceService.sessionChantdesperanceCurrentList);
      //console.log(`goToRecordSong(${ACTION_RECORD}): this.countRowIndex:: `, this.countRowIndex);
      //console.log(`goToRecordSong(${ACTION_RECORD}): item:: `, item);
      this.chantId=this.chantdesperanceService.sessionChantdesperanceSelected .id;
      //this.updateCEView();

      const queryParams = {
        tc: item.typeChant,
        ri: this.countRowIndex,
        IDG: this.groupeId,
      };

      await this.getChantDesperanceById();
      this.router.navigate([MenuLink.ChantDesperanceDetailsPublic, item.id ], { queryParams });
    }
  }

  public retour(){
    if( this.isViewOnly ){
      this.dynamicDialogRef.close();
    }else{
      this.router.navigate([ MenuLink.ChantDesperancePublic, this.groupeId ]);
    }
  }
  async checkIsAllDATA(){
    this.isFavoris=false;
    let userName = this.commonService.authorizationService.userConnected.userName;
    if( userName!=''){
      let data  = {
        userId: userName,
        chantEsperanceId: this.chantId,
        sourceApp: 'Angular WEB CCG',
        isChantAsFavoris: false
      }
      this.subs.sink = await this.chantFavorisService.checkCE( data ).subscribe({
        next:(result)=>{ this.isFavoris=result; },
      });

      this.subs.sink = await this.chantdesperanceLikeService.checkCE( data ).subscribe({
        next:(result)=>{ this.isLike=result; },
      });

      // this.subs.sink = await this.chantdesperanceHasKnowService.checkCE( data ).subscribe({
      //   next:(result)=>{ this.isKnow=result; },
      // });
    }
  }

  public get imgLike(): string {
    return this.isLike?'pi pi-thumbs-up-fill orange':'pi pi-thumbs-up ';
  }
  public get textLike(): string {
    return this.isLike?'UnLike':'Give a like';
  }
  public get textHasKnow(): string {
    return this.isKnow?'Lyric mark Know':'Lyric mark UnKnow';
  }

  public addRemove(enumAction: EnumAction){
    let userName = this.commonService.authorizationService.userConnected.userName;
    if( userName==''){
      this.loginForm(enumAction);
    }else{
      let data  = {
        userId: userName,
        chantEsperanceId: this.chantId,
        sourceApp: 'Angular WEB CCG',
      }
      switch (enumAction) {
        case EnumAction.CALL_FAVORIS_FUNC:
          this.subs.sink = this.chantFavorisService.markCE( data ).subscribe({
            next:(result)=>{this.isFavoris=!this.isFavoris;},
          });
          break;
        case EnumAction.CALL_LIKE_FUNC:
          this.subs.sink = this.chantdesperanceLikeService.markCE( data ).subscribe({
            next:(result)=>{
              this.isLike=!this.isLike;
              this.getChantDesperanceById(true);
            },
          });
          break;
        case EnumAction.CALL_HASKNOW_FUNC:
          this.subs.sink = this.chantdesperanceHasKnowService.markCE( data ).subscribe({
            next:(result)=>{ this.isKnow=!this.isKnow; },
          });
          break;
        default:
          break;
      }
    }
  }
  public get imgPiFavoris(): string {
    return this.isFavoris?'pi pi-heart-fill':'pi pi-heart';
  }
  public get imgFavoris(): string {
    return this.isFavoris?'./assets/images/ccg/heartfavorite.png':'./assets/images/ccg/heartfavorite1.png';
  }
  public get textFavoris(): string {
    return this.isFavoris?'Remove to favoris list':'Add to favoris list';
  }
  public changerTaille( taille: number ) {
    let size = this.chantdesperanceService.sessionFontSize;
    console.log(`changerTaille:BEFORE`,size);
    if( taille>0){
      this.chantdesperanceService.sessionFontSize++;
    }else{
      if( size >= 14 ){
        console.log(`changerTaille:  ${size} >= 10`);
        this.chantdesperanceService.sessionFontSize--;
      }
    }
    console.log(`changerTaille: AFTER`,this.chantdesperanceService.sessionFontSize);
  }
  public get fontSize() {
    return `font-size:${this.chantdesperanceService.sessionFontSize}px;`;
  }
  public alignPositionSelected( position: EnumPosition) {
    return `pull-right tr cp ${this.chantdesperanceService.sessionAlignPosition==position?'alignPSelected':''}`;
  }
  public get alignPosition() {
    return `${this.chantdesperanceService.sessionAlignPosition}`;
  }
  public setAlignPosition( position: EnumPosition ) {
    this.chantdesperanceService.sessionAlignPosition=position.toString();
  }
  loginForm(methodeCall: EnumAction) {
    let data = {
      isDialog: true,
    };
    const ref = this.commonService.dialogService.open(LoginDialogFormComponent, {
        //header: 'Login', contentStyle: {"overflow":"auto"}, baseZIndex: 10000, /width: '60%',
        data: data
    });

    ref.onClose.subscribe(( result ) => {
        console.log('loginForm', result);
        if (result) {
          if( result.isReloadPage ){
            this.addRemove(methodeCall);
          }
        }
    });
  }



}
