import { v4 } from './../../../../../../node_modules/@types/uuid/index.d';
import { Injectable } from '@angular/core';
import { AlbumGroupeTextField } from '@core/models/ce/album_groupe-model';
import { ApiService } from '@core/service/api/api.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { EnumAction } from '@shared/tools/enums/enum-status.enum';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class GroupeCuService {

  endpoint: string = ApiUrl.GroupeCU;
  public textField=AlbumGroupeTextField;
  public enumText= EnumText;

  constructor( private apiService: ApiService) {}

  getAll(username: string, data?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpoint}/${username}`, data );
  }
  getAllPaginate(username: string, data?: any) : Observable<any>{
    return this.apiService.getData(`${ApiUrl.GroupeCUPagination(username)}`, data );
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/FindOne/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.code}`, data, );
  }
  saves( isNew: boolean, data: any ) : Observable<any>{
    if( isNew ){
      data.code = uuid.v4();
      console.log(`INSERT:`, data);
      return this.insert(data);
    }
      console.log(`UPDATE:`, data);
    return this.update(data);
  }

  delete( id: string, username: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}/${username}` );
  }

  deleteFile( id: string, enumAction: EnumAction ) : Observable<any>{
    if( EnumAction.AUDIO==enumAction ){
      return this.apiService.patchData(`${this.endpoint}?id=${id}&isAudio=true` );
    }
    return this.apiService.patchData(`${this.endpoint}?id=${id}&isAudio=false` );
  }
}
