import { OFFSET } from "@shared/tools/constants/constants";

export class FilterPublicSimple
{
  searchStr?: string;
  offset: number = OFFSET;
  page: number = 1;
}

export class ChantFilterModel  extends FilterPublicSimple
{
  userName?: string;
  albumGroupeId?: string;
  groupeChantantId?: string;
  categorieChantId?: string;
  typeChant?: string;
  isChantChoeur?: string;
  genreMusicalIds?: string[];
  styleMusicalIds?: string[];
  artistIds?: string[];
}

export class FilterPublicModel extends FilterPublicSimple
{
  groupeId?: string;
  groupeName?: string;
  genreMusicalIds?: string[];
  genreMusicalNameDisplay?: string[];
  styleMusicalIds?: string[];
  styleMusicalNameDisplay?: string[];
  artistIds?: string[];
  artistNameDisplay?: string[];
}
