import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, InfoService, UserModel } from '@core';
import { GlobalService } from '@core/service/global.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImportALlModule } from '@shared/imports';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { environment } from 'environments/environment';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-auth-form',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './auth-form.component.html',
  styleUrl: './auth-form.component.scss'
})
export class AuthFormComponent {
  @Input() isDialog:boolean=false;

  authForm!: UntypedFormGroup;
  submitted = false;
  loading = false;
  returnUrl!: string;
  error = '';
  hide = true;
  apiInfo: string='-.-.-.-';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    public globalService: GlobalService,
    public infoService: InfoService,
    public dynamicDialogRef: DynamicDialogRef,
    public translateService: TranslateService
  ) {
  }

  ngOnInit() {
    var username= '';
    var password= '';
    if(environment.name=='QA'){
      username= 'duverseau.jeanfritz@gmail.com';
      password= 'devesha';
    }
    this.authForm = this.formBuilder.group({
      username: [username, Validators.required],
      password: [password, Validators.required],
    });
  }

  get f() {
    return this.authForm.controls;
  }

  onSubmit() {
    //this.clear();
    this.submitted = true;
    this.error = '';
    if (this.authForm.invalid) {
      this.error = 'Username and Password not valid !';
      return;
    } else {
      this.globalService.showLoader(true);
      let obj = this.authForm.value;
      this.authService.SignInWithEmailAndPasswordFireBase(obj).then((data: UserModel | any) => {
        this.globalService.showLoader(false);
        this.onClose();
      }).catch((error) => {
        this.globalService.showLoader(false);
        this.error = error;
        //console.log(error);
        //this.messageService.add({severity:Severity.error, summary: this.userService.textField.title, detail: error});
      });
    }
  }

  loginWithGoogleTS() {
    console.log('loginWithGoogleTS:.:: IN ');
    //this.clear();
    //this.submitted = true;
    this.error = '';
    if (this.authForm.invalid) {
      this.error = 'Username and Password not valid !';
      return;
    } else {
      this.globalService.showLoader(true);
      let obj = this.authForm.value;
      this.authService.SignInWithOAuthFirebase().then((data: UserModel | any) => {
        this.globalService.showLoader(false);
        this.onClose();
      }).catch((error) => {
        this.globalService.showLoader(false);
        this.error = error;
      });
    }
  }

  signup(){

  }

  onClose(){
    this.clear();
    if( this.isDialog ){
      this.dynamicDialogRef.close({isReloadPage: true });
    }else{
      this.router.navigate([`${MenuLink.Dashboard}`]);
    }
  }

  clear() {
    //this.commonService.messageService.clear();
  }
}
