import { Injectable, OnDestroy } from '@angular/core';
import { KeyValueModel } from '@core/key-value-model';
import { ChantDesperanceModel, ChantDesperanceTextField } from '@core/models/ce/chant-desperance-model';
import { MediaPlayerModel } from '@core/models/media-player-model';
import { ApiService } from '@core/service/api/api.service';
import { CommonService } from '@core/service/common/common.service';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { GLOBAL_SESSION_ALIGN_POSITION, GLOBAL_SESSION_CHANT_LIST, GLOBAL_SESSION_CHANT_SELECTED, GLOBAL_SESSION_FONT_SIZE, GLOBAL_SESSION_GROUPE_ID, GLOBAL_SESSION_TYPE_CHANT } from '@shared/tools/constants/constants';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { EnumAction } from '@shared/tools/enums/enum-status.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChantdesperanceService {

  endpoint: string = ApiUrl.ChantDesperance;
  endpointV2: string = ApiUrl.ChantDesperanceV2;

  endpointCU: string = ApiUrl.ChantUser;
  endpointPublic: string = ApiUrl.ChantUserPublic;

  public textField=ChantDesperanceTextField;
  public enumText= EnumText;

  constructor( private apiService: ApiService,  private commonService: CommonService ,  private sessionAuthService: SessionAuthService,  private mediaPlayerService: MediaPlayerService) {}

  //region [ SESSION ]
  get sessionFontSize(): number {
    let size = this.sessionAuthService.getItemVar(GLOBAL_SESSION_FONT_SIZE);
    return size==''?15:size;
  }
  set sessionFontSize(value: number) {
    this.sessionAuthService.setItem(GLOBAL_SESSION_FONT_SIZE, value);
  }

  get sessionAlignPosition(): string {
    let al = this.sessionAuthService.getItemVar(GLOBAL_SESSION_ALIGN_POSITION);
    return al==''?'tc':al;
  }
  set sessionAlignPosition(value: string) {
    this.sessionAuthService.setItem(GLOBAL_SESSION_ALIGN_POSITION, value);
  }

  get sessionChantdesperanceSelected(): ChantDesperanceModel {
    return this.sessionAuthService.getItem(GLOBAL_SESSION_CHANT_SELECTED)?? new ChantDesperanceModel();
  }
  set sessionChantdesperanceSelected(value: ChantDesperanceModel) {
    this.sessionAuthService.setItem(GLOBAL_SESSION_CHANT_SELECTED, JSON.stringify(value));
  }

  removeSessionChantdesperanceSelected() {
    this.sessionAuthService.removeItem(GLOBAL_SESSION_CHANT_SELECTED);
  }

  get sessionChantdesperanceCurrentList(): ChantDesperanceModel[] {
    return this.sessionAuthService.getItem(GLOBAL_SESSION_CHANT_LIST)??[];
  }
  set sessionChantdesperanceCurrentList(value: ChantDesperanceModel[]) {
    this.sessionAuthService.setItem(GLOBAL_SESSION_CHANT_LIST, JSON.stringify(value));
  }
  removeSessionChantdesperanceCurrentList() {
    this.sessionAuthService.removeItem(GLOBAL_SESSION_CHANT_LIST);
  }

  get sessionGroupeId(): number {
    return this.sessionAuthService.getItemVar(GLOBAL_SESSION_GROUPE_ID)??0;
  }
  set sessionGroupeId(value: number) {
    this.sessionAuthService.setItem(GLOBAL_SESSION_GROUPE_ID, String(value));
  }
  removeSessionGroupeId() {
    this.sessionAuthService.removeItem(GLOBAL_SESSION_GROUPE_ID);
  }

  get sessionTypeChant(): string {
    return this.sessionAuthService.getItemVar(GLOBAL_SESSION_TYPE_CHANT)??'';
  }
  set sessionTypeChant(value: string) {
    this.sessionAuthService.setItem(GLOBAL_SESSION_TYPE_CHANT, value);
  }
  removeSessionTypeChant() {
    this.sessionAuthService.removeItem(GLOBAL_SESSION_TYPE_CHANT);
  }
  //endregion



  getAll(data?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpoint}`, data );
  }
  getAllPaginate(data?: any) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}/paginate`, data );
  }
  getAllByGroupeV2(data?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpointV2}/by-groupe`, data );
  }

  getAllByGroupeByTypeV2(data: any) : Observable<any>{
    return this.apiService.getData(`${ApiUrl.GroupeEtTypechantV2(data)}`);
  }

  getOne( id: any ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }
  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }
  updateCEView(  id: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/UpdateCEView/${id}`, );
  }
  saves( isNew: boolean, data: any ) : Observable<any>{
    console.log(`saves:`,data);
    if( isNew ){
      return this.insert(data);
    }
    return this.update(data);
  }

  delete( id: number ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }

  deleteFile( id: number, enumAction: EnumAction ) : Observable<any>{
    if( EnumAction.AUDIO==enumAction ){
      return this.apiService.patchData(`${this.endpoint}?id=${id}&isAudio=true` );
    }
    return this.apiService.patchData(`${this.endpoint}?id=${id}&isAudio=false` );
  }


  // public playAudioOLD(row: ChantDesperanceModel, chantDesperanceList?:ChantDesperanceModel[]){
  //   console.log(`playAudio:`, row);

  //   if (row.audioPathUrl && row.audioPathUrl !== '') {
  //    let audioFile: MediaPlayerModel = {
  //       id: row.id.toString(),
  //       url:row.audioPathUrl,
  //       name: `${row.titre}`,
  //       artist: `${row.auteurChant}`,
  //       position: `${row.position}`,
  //       titre: `${row.titre}`,
  //       auteurChant: `${row.auteurChant}`,
  //       typeChant: `${row.typeChant}`,
  //       groupeChantantId: row.groupeChantantId.toString(),
  //     };
  //     if( this.mediaPlayerService.currentAudioFile?.id != row.id ){
  //       this.mediaPlayerService.openFileAudio( audioFile, row.id);
  //     }
  //     this.mediaPlayerService.play();
  //     let audioList:MediaPlayerModel[] =[];
  //     if( chantDesperanceList ){
  //       chantDesperanceList.forEach((x) => {
  //         if( x.audioPathUrl !== ''){
  //           audioList.push({
  //             id: x.id.toString(),
  //             url:x.audioPathUrl,
  //             name: `${x.titre}`,
  //             artist: `${x.auteurChant}`,
  //             position: `${x.position}`,
  //             titre: `${x.titre}`,
  //             auteurChant: `${x.auteurChant}`,
  //             typeChant: `${x.typeChant}`,
  //             groupeChantantId: x.groupeChantantId.toString(),
  //           });
  //         }
  //     });
  //     if( audioList.length >0 ){
  //       // get the indexAudio
  //       this.mediaPlayerService.countRowIndex = audioList.findIndex((x)=> x.id==row.id.toString());
  //     }
  //     this.mediaPlayerService.mediaPlayerList = audioList;
  //     console.log(`audioList:`, audioList);
  //   }
    //   let d = `<audio preload="auto" controls="" src="${row.audioPathUrl}"></audio>`;
    //   let d1 = `<audio preload="auto" autoplay controls="" src="${row.audioPathUrl}"></audio>`;
    //   row.audioSelected=d;

    //   this.mediaPlayerService.mediaPlayer.id = row.id.toString();
    //   this.mediaPlayerService.mediaPlayer.sourcePath = row.audioSelected;
    //   //this.mediaPlayerService.notify();
  //   }
  // }

  // public showDiagram.meNote(row: ChantDesperanceModel){
  //   let keyValue = new KeyValueModel();
  //   keyValue.title = row.titre!==''?row.titre: this.textField.diagrammeNotePath;
  //   keyValue.pathUrl = row.diagrammeNotePathUrl;
  //   this.commonService.showPopUpFile(keyValue);
  // }

}
