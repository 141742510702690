<!-- <form [formGroup]="formGroupPageName" (ngSubmit)="onSubmit()" > -->
  <p-messages [(value)]="message"></p-messages>

<div class="row"style="margin-top: 15px;">

  @if( !this.isCUPublic ){
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
      <span class="p-float-label">
        <p-dropdown [(ngModel)]="chantFilter.groupeChantantId" [options]="groupeDataList"  [itemSize]="10"
          [virtualScroll]="false" [filter]="false"  optionValue="code" optionLabel="nom"
          styleClass="w100"  placeholder=" -Select {{textField.groupeChantant}} -">
          <ng-template let-option  pTemplate="selectedItem">
            <img width="25" [src]="option.imagePathUrl" class="img-circle" />
            <span>
              @if(!isCU && option.code!=''){[{{option.code}}] : }
            {{option.nom}} </span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            @if(!isCU){<img width="25" [src]="option.imagePathUrl" class="iimg-circle" />}
            @if(isCU){<img width="25" [src]="option.bannerPath" class="iimg-circle" />}
            <span>
              @if(!isCU && option.code!=''){[{{option.code}}] : }
            {{option.nom}} </span>
          </ng-template>
        </p-dropdown>
        @if(chantFilter.groupeChantantId!=''){
        <label for="inputtext">{{textField.groupeChantant}}</label>
        }
      </span>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
      <span class="p-float-label">
        <p-dropdown [(ngModel)]="chantFilter.categorieChantId" [options]="categoryDataList"  [itemSize]="10"
          [virtualScroll]="false" [filter]="false"  optionValue="code" optionLabel="nom"
          styleClass="w100" placeholder=" -Select {{groupeCeService.textField.title}} -">
          <ng-template let-option  pTemplate="selectedItem">
              <div class="country-item country-item-value">
                <span>
                  @if(!isCU && option.code!=''){[{{option.code}}] : }
                {{option.nom}} </span>
              </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>
              @if(!isCU && option.code!=''){[{{option.code}}] : }
            {{option.nom}} </span>
          </ng-template>
        </p-dropdown>
        @if(chantFilter.categorieChantId!=''){
        <label for="inputtext">{{textField.categorieChant}}</label>
        }
      </span>
    </div>

    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 mb-2">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Keyword search</mat-label>
        <input [(ngModel)]="chantFilter.searchStr" matInput id="searchStr">
        <mat-icon matSuffix class="search-icon">search</mat-icon>
      </mat-form-field>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2">
      <span class="p-float-label">
        <p-dropdown [(ngModel)]="chantFilter.typeChant"  [options]="typeChantList"  [itemSize]="10"
          [virtualScroll]="false" [filter]="false" optionValue="value" optionLabel="label"
          styleClass=""  placeholder="{{textField.typeChant}}">
            <ng-template let-option  pTemplate="selectedItem">
                <span>{{option.label}}:{{option.value}} </span>
            </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option.label}} </span>
          </ng-template>
        </p-dropdown>
        @if(chantFilter.typeChant!=''){
        <label for="inputtext">{{textField.typeChant}}</label>
        }
      </span>
    </div>
    @if(!isCU){
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 mb-2">
        <span class="p-float-label">
        <p-dropdown [(ngModel)]="chantFilter.isChantChoeur" [options]="keyValueYesNoList"  [itemSize]="10"
          [virtualScroll]="true" [filter]="false"  optionValue="value" optionLabel="label"
          styleClass=""  placeholder=" - is choir -">
          <ng-template let-option  pTemplate="selectedItem">
            <span>{{option.label}}:{{option.value}} </span>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <span>{{option.label}} </span>
          </ng-template>
        </p-dropdown>
        @if(chantFilter.isChantChoeur!=''){
        <label for="inputtext">{{textField.isChantChoeur}}</label>
        }
      </span>
      </div>
    }
  }
  @if( this.isCUPublic ){
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
      Genre <span *ngIf="genreMusicalService.genreMusicalDataList.length>0">
        (<b>{{genreMusicalService.genreMusicalDataList.length}}</b> items)
      </span>
      <p-listbox
        [options]="genreMusicalService.genreMusicalDataList"
        [(ngModel)]="genreMusicalService.genreMusicalDataListSelected"
        optionLabel="name"
        [filter]="true"
        [multiple]="true"
        [metaKeySelection]="false"
        [listStyle]="{ 'max-height': '250px' }"
        (onChange)="onChangeGenre($event)">
        <ng-template let-obj pTemplate="item">
          <div [pTooltip]="obj.descriptions" tooltipPosition="top">{{ obj.name }}</div>
        </ng-template>
      </p-listbox>
      <span>
        <b>{{genreMusicalService.genreMusicalDataListSelected.length}}</b> items selected.
      </span>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
      Style <span *ngIf="genreMusicalService.styleMusicalDataList.length>0">
        (<b>{{genreMusicalService.styleMusicalDataList.length}}</b> items)
      </span>
      <p-listbox
        [options]="genreMusicalService.styleMusicalDataList"
        [(ngModel)]="genreMusicalService.styleMusicalDataListSelected"
        optionLabel="name"
        [filter]="true"
        [multiple]="true"
        [metaKeySelection]="false"
        [listStyle]="{ 'max-height': '250px' }"
        (onSelectAllChange)="onSelectAllChange($event)"
        (onChange)="onChangeStyle($event)">
        <ng-template let-obj pTemplate="item">
          <div [pTooltip]="obj.descriptions" tooltipPosition="top">{{ obj.name }}</div>
        </ng-template>
      </p-listbox>
      <span>
        <b>{{genreMusicalService.styleMusicalDataListSelected.length}}</b> items selected.
      </span>
    </div>
  }

</div>

 <div class="ibox-content">
   <br style="clear: both;" />
   <div class="hr-line-dashed"></div>
   <div class="form-group">
       <div class="col-sm-12">
           <button type="button" (click)="onClose()" class="btn btn-danger">{{ enumText.Cancel }}</button>&nbsp;&nbsp;

           <button type="button" (click)="onSubmit()" class="btn btn-primary" value="Save" >{{enumText.StartSearch}}</button>
       </div>
   </div>
</div>
<!-- </form> -->

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
