<div class="hearderPaddingNear"></div>
<div class="container">

  <!-- <p-breadcrumb class="myBreadcrumbCE" [model]="menuItemBreadcrumb">
    <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item.route; else elseBlock">
            <a [routerLink]="item.route" class="p-menuitem-link"
              pTooltip="Click to go back" tooltipPosition="bottom">
                <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
                &nbsp;<span class="text-primary font-semibold">{{ item.label }}</span>
            </a>
        </ng-container>
        <ng-template #elseBlock>
            <a [href]="item.url">
              <span class="text-color">{{ item.label }}</span>
            </a>
        </ng-template>
    </ng-template>
  </p-breadcrumb> -->

  <div class="card1">
    <div class="row clearfix"style="padding-bottom: 20px;">


      <div class="col-lg-3 col-md-12">
        <div class="card1">
         <app-panel-search-bar-laterale (onReloadSearchEventEmitter)="loadData()"></app-panel-search-bar-laterale>

          <app-panel-bar-verticale-one></app-panel-bar-verticale-one>

        </div>
      </div>

      <div class="col-lg-9 col-md-12">
        <p-table #dt2
          dataKey="id"
          [value]="chantUserList"
          [paginator]="false"
          [rows]="pageEvent.rows"
          [loading]="globalService.isLoading"
          [showCurrentPageReport]="true"
          [globalFilterFields]="['noChant','title']"
          styleClass="p-datatable-gridlines"  >

          <ng-template pTemplate="body" let-row>
            <tr class="cp">
                <!-- <td class="w3px" (click)="navigateToCEDetailsPublic(row)">
                  <div class="bullOrange">{{row.position}}</div>
                </td> -->
                <td>
                  <span class="pull-left w80" (click)="navigateToCEDetailsPublic(row)">
                      <span class="title">
                        {{row.position}}- {{row.titre}}
                      </span>
                      <span class="displayb" *ngIf="row.auteurChant!=''">
                        <i class="fa fa-user"></i>: {{row.auteurChant}}
                      </span>
                  </span>
                  <span *ngIf="row.audioPath || row.diagrammeNotePath" class="pull-right tr">
                    @if (row.audioPath)
                    {
                      <img id="imgBtnAudio" width="30" src="./assets/images/ic_song.png" class="img-circle cp" (click)="mediaPlayerService.playAudio(row, chantUserList)" />
                    }
                    @if (row.diagrammeNotePath)
                    {
                      <img id="imgBtnDiagrammeNote" width="30" src="./assets/images/ic_solfege.png" class="img-circle cp" (click)="mediaPlayerService.showDiagrammeNote(row)" />
                    }
                  </span>
                  <!-- <div [innerHTML]="row.audioSelected"></div> -->
                </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10">{{enumText.NoDatafound}}...</td>
            </tr>
          </ng-template>
        </p-table>
        <div class="card1 flex1 justify-content-center">
          <p-paginator
              (onPageChange)="onPageChange($event)"
              [first]="pageEvent.first"
              [rows]="pageEvent.rows"
              [totalRecords]="pageEvent.pageCount"
              [rowsPerPageOptions]="[5, 10, 20, 30, 40, 50, 100]" />
        </div>
      </div>

    </div>
      <br />
      <br />
      <br />
      <br />
      <br />


  </div>

</div>
