export class GroupeCeModel {
  code: string='';
  nom: string='';
  color: string='';
  imagePath: string='';
  imagePathUrl: string='';
  imagePathBase64: string='';
  bannerTopPath: string='';
  bannerTopPathUrl: string='';
  bannerTopPathBase64: string='';

  id: string='';
  nomWithOutBreak: string='';
  isCE:boolean = false;
  isShow:boolean = false;
  cssClass: string='';
}

export class GroupeCEdto{
  groupeCEList: GroupeCeModel[]=[];
  groupeCEEBVList: GroupeCeModel[]=[];
}

export enum GroupeCeTextField {
  title= 'Group CE',
  code= 'Code',
  imagePath= 'logo',
  nom= 'Name',
}
export const groupeCeVIDE = {
  code: '',
  nom: 'All Group',
  imagePath: '',
  color: '',
  imagePathUrl: './assets/images/ccg/imgchantesperance.png',

  id: '',
  nomWithOutBreak: '',
  isCE: false,
  isShow: false,
  cssClass: '',

  imagePathBase64: '',
  bannerTopPath: '',
  bannerTopPathUrl: '',
  bannerTopPathBase64: '',
}
