<div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true"
  class="vc_row wpb_row vc_row-fluid dgt-bg-inherit vc_custom_1484724465949 vc_row-has-fill vc_row-no-padding">
    <div class="wpb_column vc_column_container vc_col-sm-12">
      <div class="vc_column-inner">
        <div class="wpb_wrapper">
          <div class="dgt-custom-heading vc_custom_heading vc_custom_1592407805378 dgt-al-center">
            <h2 style="color: #ffffff;text-align: center" class="dgt-heading">Search Song Lyrics</h2>
            <div class="container pos-center2 tc">
              <div class="lyric-search-input tc">
                <div class="search-wrapper clearfix tc">
                  <span class="p-input-icon-left tc w100 lisinput">
                    <p-iconField iconPosition="right" class="ml-auto">
                      <p-inputIcon>
                          <i class="pi pi-search" (click)="searchData()"></i>
                      </p-inputIcon>
                      <input pInputText [(ngModel)]="filterPublic.searchStr" id="searchStr" type="search" class="lisinput"
                        (keyup.enter)="searchData()"
                        placeholder="Search: Enter lyrics name keywords hit enter" />
                    </p-iconField>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container">

  <div *ngIf="groupeDataList.length>0" class="GroupeCEPublic">
    <app-title-brand titleIcon="fa fa-star" title="Chant d'Esperance" [linkSeeMore]="menuLink.GroupeCEPublic" ></app-title-brand>
    <p-carousel
      [value]="groupeDataList"
      [numVisible]="6"
      [numScroll]="3"
      [circular]="true"
      [responsiveOptions]="responsiveOptions"
      autoplayInterval="6000">
        <ng-template let-gce pTemplate="item">
          <div class="cp"  (click)="goToGroupeCE(gce.code)">
            <div class="mb-3 tc">
              <div class="relative mx-auto tc">
                <img
                    [src]="gce.imagePathUrl"
                    [alt]="gce.nom"
                    class="w-full border-round" />
              </div>
            </div>
            <div class="font-medium tc">
              {{ gce.nom }}
            </div>
          </div>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5335107298173193"
              crossorigin="anonymous"></script>
          <ins class="adsbygoogle"
              style="display:block"
              data-ad-format="fluid"
              data-ad-layout-key="+5+rx+1+2-3"
              data-ad-client="ca-pub-5335107298173193"
              data-ad-slot="4515407264"></ins>
          <script>
              (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </ng-template>
    </p-carousel>
  </div>

  <app-popular-lyrics [offset]="10"></app-popular-lyrics>

  <!-- <app-song-items-columns-3></app-song-items-columns-3> -->
  <div *ngIf="latestChantUserList.length>0" class="LatestLyrics">
    <app-title-brand titleIcon="fa fa-clock" title="Latest lyrics" classDiv="b-b" [linkSeeMore]="menuLink.ChantUserPublic" ></app-title-brand>
    <div class="latest-lyrics margint10">
      <ul>
        @for (item of latestChantUserList; track item; let i = $index) {
          <li *ngIf="i==0||i==6||i==13">
            <ins class="adsbygoogle"
                style="display:block"
                data-ad-format="fluid"
                data-ad-layout-key="+5+rx+1+2-3"
                data-ad-client="ca-pub-5335107298173193"
                data-ad-slot="4515407264"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          </li>
          <li class="cp" (click)="navigateToCUDetailsPublic(item)">
            <div class="lyric-content">
                <h5>
                  <a [routerLink]="[menuLink.ChantUserDetailsPublic, item.id]">{{i}}-{{item.titre}}</a>
                </h5>
                <a [routerLink]="[menuLink.ChantUserDetailsPublic, item.id]">{{item.auteurChant}}</a>
            </div>
          </li>
        }
      </ul>
    </div>
  </div>

  <!-- <div class="card flex justify-content-center slider1">
    <p-carousel
        [value]="products"
        [numVisible]="1"
        [numScroll]="1"
        orientation="horizontal"
        verticalViewPortHeight="360px"
        [circular]="false" class="mySlider"
        >
            <ng-template let-product pTemplate="item">
              <div data-vc-full-width="true" data-vc-full-width-init="true" data-vc-stretch-content="true"
              class="vc_row wpb_row vc_row-fluid dgt-bg-inherit vc_custom_1484724465949 vc_row-has-fill vc_row-no-padding">
                <div class="wpb_column vc_column_container vc_col-sm-12">
                  <div class="vc_column-inner">
                    <div class="wpb_wrapper">
                      <div class="dgt-custom-heading vc_custom_heading vc_custom_1592407805378 dgt-al-center">
                        <h2 style="color: #ffffff;text-align: center" class="dgt-heading">Propos de Bienvenue</h2>
                        <span class="dgt-heading-icon">
                          <img src="assets/images/ccg/heading-icon-white.png"
                          width="93" height="33" alt="Icon Heading"
                          src-orig="assets/images/ccg/heading-icon-white.png" scale="2">
                        </span>
                        <p class="dgt-sub-title" style="color: #b4b4b4">
                          Bienvenue sur le site de la Paroisse Notre-Dame d’Altagrâce, Delmas-Haiti,<br>
                          Vous y trouverez des informations sur toutes ses activités religieuses, sociales, et autres.
                          Ce site vous permet de découvrir les événements marquants de la Paroisse, de l’Eglise en Haïti et de l’Eglise Universelle.
                          Il met aussi à votre disposition des prières, des enseignements pour alimenter votre vie spirituelle et nourrir votre foi.
                        </p>
                      </div>

                      <div class="dgt-vc-element dgt-element-button dgt-al-center">
                        <a class="dgt-button dgt-button-white dgt-button-default" href="https://www.paroissenotredamedaltagrace.com/Site/contact/">Rejoignez nous maintenant</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
    </p-carousel>
  </div> -->
</div>
