<h6 class="b-b p-10 text-strong">Search criteria</h6>
<div class="table-header tc" style="margin-bottom: 10px;">
  <p-iconField iconPosition="left" class="ml-auto" style="width: 99%;">
    <p-inputIcon>
        <i class="pi pi-search"></i>
    </p-inputIcon>
    <input pInputText [(ngModel)]="filterPublic.searchStr" id="searchStr"
    (keyup.enter)="search()" type="text" placeholder="Search keyword"
    style="width: 99%;" />
  </p-iconField>
  &nbsp;
  <button (click)="search()" type="button" class="btn btn-primary"
    pTooltip="{{enumText.SearchAdvanced}}" tooltipPosition="top">
    <i class="pi pi-search"></i>
  </button>

  <!-- <button *ngIf="initSearch" (click)="clear(0)" type="button" class="btn btn-outline"
  pTooltip="{{enumText.ClearSearch}}" tooltipPosition="top">
    <i class="pi pi-filter-slash"></i>
  </button> -->
</div>

<div>
  <button (click)="showFormSearchAdvanced()" type="button" class="btn btn-primary"
  pTooltip="{{enumText.ClearSearch}}" tooltipPosition="top" style="width: 100%;">
    <i class="pi pi-filter-slash"></i> Advance
  </button>
</div>

<div style="padding-top: 10px;">
  <div *ngIf="filterPublic.searchStr && filterPublic.searchStr!==''">
    <p-chip [label]="filterPublic.searchStr" [removable]="true" (click)="clear(enumFields.SEARCH_STR, '')" />
  </div>

  <div *ngIf="filterPublic.groupeId && filterPublic.groupeId!==''">
    <h6 class="b-b p-10 text-strong">Album/Group</h6>
    <p-chip [label]="filterPublic.groupeName" [removable]="true" (click)="clear(enumFields.GROUPE_ID, '')" />
  </div>

  <div class="clearfix" *ngIf="filterPublic.genreMusicalNameDisplay">
    <h6 class="b-b p-10 text-strong">Genre</h6>
    @for (genre of filterPublic.genreMusicalNameDisplay; track genre) {
      <div class="chip"><p-chip [label]="genre" [removable]="true" (click)="clear(enumFields.GENRE_MUSICAL_ID, genre)" /></div>
    }
  </div>

  <div class="clearfix" *ngIf="filterPublic.styleMusicalNameDisplay">
    <h6 class="b-b p-10 text-strong">Style</h6>
    @for (style of filterPublic.styleMusicalNameDisplay; track style) {
      <div class="chip"><p-chip [label]="style" [removable]="true" (click)="clear(enumFields.STYLE_MUSICAL_ID, style)" /></div>
    }
  </div>

  <!-- <p-chip *ngIf="filterPublic.styleMusicalId && filterPublic.styleMusicalId!==''" [label]="filterPublic.styleMusicalId" [removable]="true" (click)="clear(enumFields.STYLE_MUSICAL_ID)" /> -->
  <!-- <p-chip *ngIf="filterPublic.artistId && filterPublic.artistId!==''" [label]="filterPublic.artistId" [removable]="true" (click)="clear(enumFields.ARTIST_ID)" /> -->

</div>
